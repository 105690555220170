window.rwd || (window.rwd = {});

;(function($package) {
    var HeightUtil = {
        resetHeight: function(items) {
            $(items).outerHeight("");
        },
        syncHeight: function syncHeight(items) {
            var maxHeight = 0;
            $(items).each(function() {
                var $el = $(this);
                var height = $el.outerHeight();
                if (height > maxHeight) {
                    maxHeight = height;
                }
            }).height(maxHeight);
        },
        resetAndSyncHeight: function(items, cb) {
            HeightUtil.resetHeight(items);
            HeightUtil.syncHeight(items);
            if ( typeof cb === "function" ) {
                cb();
            }
        },
        itemsInSets: function($items, count) {
            var sets = [];
            for (var i=0; i < $items.length; i+=count) {
                sets.push($items.slice(i, i+count));
            }
            return sets;
        },
        itemsBySelector: function($items, selector) {
            var sets = [];
            $items.each(function() {
                sets.push($(this).find(selector));
            });
            return sets;
        },
        resetHeightInSets: function(itemSets) {
            $.each(itemSets, function(index, items) {
                   HeightUtil.resetHeight(items);
            });
        },
        resetAndSyncHeightInSets: function(itemSets) {
            $.each(itemSets, function(index, items) {
                   HeightUtil.resetAndSyncHeight(items);
            });
        }
    }
    $package.HeightUtil = HeightUtil;
})(rwd);

;(function($package, HeightUtil) {
    function HeightSync(node, options) {
        this.options = $.extend({}, HeightSync.defaults, options);
        this.node = node;
        this.syncHeightTimeout = -2;
    }
    HeightSync.defaults = {
        waitForImages: true,
        itemsSelector: "> *",
        syncRoutine: function(node) {
            HeightUtil.resetAndSyncHeight($(node).find(this.options.itemsSelector));
        },
        unsyncRoutine: function(node) {
            HeightUtil.resetHeight($(node).find(this.options.itemsSelector));
        }
    }
    HeightSync.prototype.sync = function() {
        var that = this;
        this._syncTestCleanup();

        var loaded = true;
        if (loaded && this.options.waitForImages) {
            loaded = HeightSync._imagesLoadedTest(this.node, this.options.itemsSelector + " IMG");
        }

        if (loaded) {
            // Trochę trwa zanim z romiaru IMG przed przeskalowaniem za pomocą css wyniknie prawidłowy
            // rozmiar i w efekcie rozmiar kontenera
            this.syncHeightTimeout = setTimeout(function() {
                that._sync0();
            }, 0);
        } else {
            this.syncHeightTimeout = setTimeout(function() {
                that.sync.apply(that, arguments);
            }, 200);
        }
    }
    HeightSync.prototype._sync0 = function() {
        this.options.syncRoutine.call(this, this.node);
    }
    HeightSync.prototype.reset = function() {
        this._syncTestCleanup();
        this.options.unsyncRoutine.call(this, this.node);
    }
    HeightSync.prototype._syncTestCleanup = function() {
        clearTimeout(this.syncHeightTimeout);
        this.syncHeightTimeout = -1;
    }
    HeightSync._imagesLoadedTest = function(node, selector) {
         var loaded = true;
         $(node).find(selector).each(function() {
             // FF pokazuje rozmiar przed przeskalowaniem
             if (this.height == 0) {
                 loaded = false;
                 return false;
             }
         });
         return loaded;
    }
    $package.HeightSync = HeightSync;
})(rwd, rwd.HeightUtil);
